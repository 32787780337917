<template lang="pug">
    .subscriber.view
        .content-wrapper
            .header
                .h2 Отправитель
                el-button(icon="el-icon-delete" type="warning" size="small" plain) Удалить отправителя
            .content.full-width.transparent
                .h1 {{ sender.name }}
                .totals-cards
                    div
                        b ?
                        div
                            div подписок
                            .details всего
                    div
                        b ?
                        div
                            div отписок
                            .details всего
                .subscriber-details
                    div(v-if="sender.created_by") Создан 79660000000 (SMS Traffic) {{ $moment(sender.created_at).local().format('lll') }}
                    div(v-else) Создан {{ $moment(sender.created_at).local().format('lll') }}

</template>

<script>
    import { mapActions } from 'vuex'
    import { GET_SENDER } from '@/store/types'

    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        props: [
            'id'
        ],
        data() {
            return {
                sender: {},
            }
        },
        computed: {
            title(){
                return `Sender ${this.sender.name}`
            },
        },
        created(){
            this.GET_SENDER(this.id).then((sender)=>{
                this.sender = sender
            }).catch((error)=>{
                this.$root.pushAppMessages(error.response.data)
            })
        },
        methods: {
            ...mapActions([
                GET_SENDER
            ]),
        },
    }
</script>

<style lang="scss" scoped>
    .subscriber{
        .header{
            display: flex;
            align-items: center;
            padding-bottom: $padding-global;

            .h3{
                margin-left: 1rem;
            }
        }
        .totals-cards{
            padding-top: $padding-global;
        }
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-details{
            padding-top: $padding-global;
        }
    }
</style>
